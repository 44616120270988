// 已完成任务
<template>

    <div class="didTaskTable">
        <div class="searchBar">
            <el-form :model="searchForm"
                     ref="searchForm"
                     :inline="true">
                <el-form-item label="任务名称"
                              prop="name">
                    <el-input v-model="searchForm.name"
                              placeholder="请输入任务名称"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="执行人"
                              prop="executorIds">
                    <el-input v-model="searchForm.executorNames"
                              placeholder="请选择执行人"
                              clearable
                              @clear="cleanexecutorIds"
                              @focus="openselectPerson">
                        <i slot="suffix"
                           v-show="!searchForm.executorIds"
                           class="el-icon-arrow-down"></i>
                    </el-input>
                    <pop-select-members v-if="selectPersonVisible"
                                        :showCheckbox="true"
                                        title="选择执行人"
                                        @close="closeselectPerson"></pop-select-members>
                </el-form-item>
                <el-form-item label="关联项目"
                              prop="projectId">
                    <com-select-item-for-keyword ref="selectTree" @selectItem="selectItem"></com-select-item-for-keyword>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="getList">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type=""
                               @click="resetSearchForm">清除</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="tableBox">

            <!-- 组件 表格上方的控制条：导入 新建 同步 等功能按钮 -->
            <com-table-control-bar :showBtn="['ImportTask', 'NewTask']"
                                   :detailRole="detailRole"
                                   @update="update"></com-table-control-bar>

            <el-table :data="tableData.data"
                      :height="tableHeight"
                      border
                      stripe>
                <el-table-column prop="id"
                                 label="控制"
                                 align="center"
                                 min-width="50">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status"
                                   active-color="#13ce66"
                                   inactive-color="#E7E7E7"
                                   :active-value="true"
                                   :inactive-value="false"
                                   v-role="commonJs.getBtnRoles(detailRole,'Btn_完成任务')"
                                   @change="changeTaskStatus(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column v-for="col in tableData.columns"
                                 :prop="col.id"
                                 :key="col.id"
                                 :label="col.label"
                                 align="center"
                                 :min-width="col.width">
                    <template slot-scope="scope">
                        <div v-if="col.id === 'projectName'">
                            <span v-if="scope.row.reserveProjectId">
                                {{scope.row[col.id] ? `${scope.row[col.id]}（机会）` : '暂无' }}
                            </span>
                            <span v-else>
                                {{scope.row[col.id] || '暂无' }}
                            </span>
                        </div>
                        <span v-else>
                            {{scope.row[col.id] || '暂无'}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="id"
                                 label="操作"
                                 align="center"
                                 min-width="100">
                    <template slot-scope="scope">
                        <!-- 表格操作组件 -->
                        <com-table-caozuo :item="scope.row"
                                          :showBtn="['TaskDetails', 'Del']"
                                          :detailRole="detailRole"
                                          @update="update"></com-table-caozuo>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination :page="searchForm.page"
                        :limit="searchForm.size"
                        :total="pageTotal"
                        @pagination="paginationChange" />
        </div>

    </div>

</template>

<script>
import PopSelectMembers from "../../../../../components/selectTree/popSelectMembers";
import ComSelectItemForKeyword from "@/modleComponents/project/comSelectItemForKeyword";
import ComTableCaozuo from "../../comTableCaozuo";
import ComTableControlBar from "../../comTableControlBar";
import { Loading } from "element-ui";
import API from "@/api/taskManage.js";
import Pagination from "@/components/Pagination"; // 分页
export default {
    name: "didTaskTable",

    props: [],

    components: {
        PopSelectMembers,
        ComSelectItemForKeyword,
        ComTableCaozuo,
        ComTableControlBar,
        Pagination,
    },

    data() {
        return {
            searchForm: {
                name: "", // 任务名称
                projectId: "", // 关联项目
                executorIds: [], // 执行人
                executorNames: "", // 执行人名字
                status: 1, // 已完成
                page: 1,
                size: 20,
            },
            pageTotal: 0,

            selectPersonVisible: false, // 选择执行人弹窗显示

            tableData: {
                data: [],
                columns: [
                    { id: "name", label: "任务名称", width: "100" },
                    { id: "executorName", label: "任务执行人", width: "100" },
                    { id: "projectName", label: "关联项目（项目机会）", width: "100" },
                    {
                        id: "estimateHours",
                        label: "预计工时（h）",
                        width: "100",
                    },
                    {
                        id: "currentHours",
                        label: "实际工时（h）",
                        width: "100",
                    },
                    { id: "startTime", label: "开始时间", width: "100" },
                    { id: "finishTimeFormat", label: "完成时间", width: "100" },
                ],
            },
            detailRole: null, // 权限数据
        };
    },

    created() {},

    mounted() {
        // 更新数据
        this.update();
    },

    methods: {
        // 更新数据
        update() {
            this.getList();
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.getHandoutTaskViewTable(this.searchForm)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    this.tableData.data.forEach((item) => {
                        item.sonCount = item.allSubTaskNum
                            ? `${item.allSubTaskNum}/${item.finishSubTaskNum}`
                            : 0;
                    });
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 选择选择执行人经理
        openselectPerson() {
            this.selectPersonVisible = true;
        },
        // 关闭选择选择执行人经理弹框
        closeselectPerson({ idArr, names }) {
            if (idArr) {
                this.searchForm.executorIds = idArr;
                this.searchForm.executorNames = names;
            }
            this.selectPersonVisible = false;
        },
        // 清空执行人
        cleanexecutorIds() {
            this.searchForm.executorIds = [];
            this.searchForm.executorNames = "";
        },
        // 选中关联项目
        selectItem(id) {
            this.searchForm.projectId = id;
        },
        // 分页改变
        paginationChange(e) {
            this.searchForm.page = e.page;
            this.searchForm.size = e.size;
            this.getList();
        },
        // 改变任务状态
        changeTaskStatus(item) {
            this.commonJs
                .changeTaskStatus(item.id, item.status)
                .then(() => {
                    // this.update();
                })
                .catch(() => {
                    // this.update();
                });
        },

        //清空筛选条件
        resetSearchForm() {
            this.$refs.selectTree.cleanSelect(); //清空项目
            this.searchForm.name = "";
            this.searchForm.executorNames = "";
            this.searchForm.projectId = "";
            this.getList();
        },
    },

    computed: {
        // 权限数据
        roleInfo() {
            return this.$store.state.role.roleInfo;
        },
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 415 });
        },
    },

    watch: {
        //角色按钮权限
        roleInfo: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.detailRole = this.commonJs.tabIsAlive(
                    val,
                    "我分配_已完成任务"
                );
            },
        },
    },
};
</script>

<style lang="scss" scoped>
// .didTaskTable{

// }
</style>
