// 我分配的任务
<template>

    <div class="comMyDidTask">
        <div class="tabTable blueLine">
            <el-tabs v-model="activeName"
                     @tab-click="handleClick">
                <el-tab-pane label="任务详情视图"
                             name="comtaskviewtable"></el-tab-pane>
                <el-tab-pane label="已完成任务"
                             name="comdidtasktable"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="tabContentBox">
            <!-- 任务详情视图 comtaskviewtable -->
            <div class="taskView"
                 v-if="activeName === 'comtaskviewtable'">
                <com-task-view-table ref="comtaskviewtable"></com-task-view-table>
            </div>
            <!-- 已完成任务 comdidtasktable -->
            <div class="myHandOutTask"
                 v-if="activeName === 'comdidtasktable'">
                <com-did-task-table ref="comdidtasktable"></com-did-task-table>
            </div>
        </div>
    </div>

</template>

<script>
import ComDidTaskTable from "./comDidTaskTable";
import comTaskViewTable from "./comTaskViewTable";
export default {
    name: "comMyDidTask",

    props: [],

    components: {
        ComDidTaskTable,
        "com-task-view-table": comTaskViewTable,
    },

    data() {
        return {
            activeName: "comtaskviewtable",
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 切换Tab
        handleClick(tab, event) {
            console.log(tab, event);
        },
    },

    computed: {},

    watch: {
        // 监听tab切换
        activeName: {
            handler() {
                if (this.activeName) {
                    this.$nextTick(function () {
                        this.$refs[this.activeName].getList();
                    });
                }
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped>
// .comMyDidTask{

// }
</style>
