// 任务类型管理
// popTaskTypeManage
<template>

    <div class="popTaskTypeManage">
        <el-dialog title="任务类型管理"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   width="30%"
                   :before-close="close">
            <el-row :gutter="15">
                <el-col :span="12">
                    <div class="title">任务分类：</div>
                    <div class="list kindofTaskList">
                        <ul v-if="kindofTaskList.length > 0">
                            <li v-for="(item,idx) in kindofTaskList"
                                :key="idx"
                                :class="{'current': chooseTaskKind.code === item.code}"
                                @click="selectTaskKind(item)">
                                <p class="name">{{item.name}}</p>
                                <p class="controlBox">
                                    <i class="el-icon-edit"
                                       @click="openpopEditTaskKind(item)"></i>
                                    <el-popconfirm confirm-button-text="删除"
                                                   cancel-button-text="取消"
                                                   icon="el-icon-info"
                                                   icon-color="red"
                                                   @confirm="delTaskKind(item.id)"
                                                   title="真的要删除吗？">
                                        <i class="el-icon-delete"
                                           slot="reference"></i>
                                    </el-popconfirm>
                                </p>
                            </li>
                        </ul>
                        <div class="emptyBg"
                             style="height: 328px"
                             v-else></div>
                        <div class="addBtn"
                             @click="openpopEditTaskKind()">+ 任务分类</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="title">任务类型：</div>
                    <div class="list taskTypeList">
                        <ul v-if="taskTypeList.length > 0">
                            <li v-for="(item,idx) in taskTypeList"
                                :key="idx">
                                <p class="name">{{item.name}}</p>
                                <p class="controlBox">
                                    <i class="el-icon-edit"
                                       @click="openpopEditTaskType(item)"></i>
                                    <el-popconfirm confirm-button-text="删除"
                                                   cancel-button-text="取消"
                                                   icon="el-icon-info"
                                                   icon-color="red"
                                                   @confirm="delTaskType(item.id)"
                                                   title="真的要删除吗？">
                                        <i class="el-icon-delete"
                                           slot="reference"></i>
                                    </el-popconfirm>
                                </p>
                            </li>
                        </ul>
                        <div class="emptyBg"
                             style="height: 328px"
                             v-else></div>
                        <div class="addBtn"
                             @click="openpopEditTaskType()">+ 任务类型</div>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>

        <!-- 编辑分类 -->
        <pop-edit-task-kind v-if="popEditTaskKindVisible"
                            :editTaskKindPopTitle="editTaskKindPopTitle"
                            :editTaskKindItem="editTaskKindItem"
                            @update="update"
                            @close="closepopEditTaskKind"></pop-edit-task-kind>

        <!-- 任务类型 -->
        <pop-edit-task-type v-if="popEditTaskTypeVisible"
                            :editTaskTypePopTitle="editTaskTypePopTitle"
                            :editTaskTypeItem="editTaskTypeItem"
                            :dictId="chooseTaskKind.id"
                            :dictName="chooseTaskKind.name"
                            @update="update"
                            @close="closepopEditTaskType"></pop-edit-task-type>

    </div>
</template>

<script>
import PopEditTaskType from "./popEditTaskType";
import PopEditTaskKind from "./popEditTaskKind";
import { Loading } from "element-ui";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "popTaskTypeManage",

    props: [],

    components: {
        PopEditTaskType,
        PopEditTaskKind,
    },

    data() {
        return {
            dialogVisible: true,
            /**
             * 任务分类
             */
            popEditTaskKindVisible: false, // 编辑分类弹窗显示
            // 任务分类数据
            kindofTaskList: [],
            editTaskKindItem: null, // 编辑任务分类的数据
            editTaskKindPopTitle: "新增任务分类",
            /**
             * 任务类型
             */
            popEditTaskTypeVisible: false, // 编辑分类弹窗显示
            // 任务类型数据
            taskTypeList: [],
            taskTypeName: "",
            editTaskTypeItem: null, // 编辑任务类型的数据
            editTaskTypePopTitle: "新增任务类型",
            chooseTaskKind: "", // 选中的任务分类
        };
    },

    created() {},

    mounted() {
        // 更新数据
        this.update();
    },

    methods: {
        // 更新数据
        update() {
            // 获取列表
            this.getList();
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".kindofTaskList"),
            });
            commonAPI
                .getTaskTypeOpts("WORK_TASK_TYPE")
                .then((res) => {
                    loading.close();
                    this.kindofTaskList = res.content;
                    // 获取任务类型列表
                    if (this.kindofTaskList.length > 0) {
                        if (!this.chooseTaskKind) {
                            this.chooseTaskKind = this.kindofTaskList[0];
                        }
                        this.getTaskTypeList();
                    }
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取任务类型列表
        getTaskTypeList() {
            let loading = Loading.service({
                target: document.querySelector(".taskTypeList"),
            });
            commonAPI
                .getModuleCode(this.chooseTaskKind.code)
                .then((res) => {
                    loading.close();
                    this.taskTypeList = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 选择任务分类
        selectTaskKind(item) {
            this.chooseTaskKind = item;
            // 获取任务类型列表
            this.getTaskTypeList();
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 任务分类 打开弹框
        openpopEditTaskKind(item) {
            if (item) {
                this.editTaskKindItem = item;
                this.editTaskKindPopTitle = "编辑任务分类";
            } else {
                this.editTaskKindItem = null;
                this.editTaskKindPopTitle = "新增任务分类";
            }
            this.popEditTaskKindVisible = true;
        },
        // 任务分类 关闭弹框
        closepopEditTaskKind(item) {
            if (item) {
                this.kindofTaskList.push(item);
            }
            this.popEditTaskKindVisible = false;
            this.editTaskKindItem = {};
        },
        // 删除任务分类
        delTaskKind(id){
            let loading = Loading.service({target: document.querySelector(".kindofTaskList")});
            commonAPI.delDict(id)
                .then(() => {
                    loading.close();
                    this.chooseTaskKind = null;
                    this.taskTypeList = [];
                    this.update();
                    this.$message({message: "操作成功！",type: "success"});
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 删除任务类型
        delTaskType(id){
            let loading = Loading.service({target: document.querySelector(".taskTypeList")});
            commonAPI.delDictItem(id)
                .then(() => {
                    loading.close();
                    this.update();
                    this.$message({message: "操作成功！",type: "success"});
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 任务类型 打开弹框
        openpopEditTaskType(item) {
            if (item) {
                this.editTaskTypeItem = item;
                this.editTaskTypePopTitle = "编辑任务分类";
            } else {
                this.editTaskTypePopTitle = "新增任务分类";
            }
            this.popEditTaskTypeVisible = true;
        },
        // 任务类型 关闭弹框
        closepopEditTaskType(item) {
            if (item) {
                this.taskTypeList.push(item);
            }
            this.popEditTaskTypeVisible = false;
            this.editTaskTypeItem = {};
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.popTaskTypeManage .title {
    margin-bottom: 15px;
}
.popTaskTypeManage .list {
    padding: 0 5px 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.popTaskTypeManage ul {
    padding: 0 5px;
    height: 300px;
    overflow-y: auto;
}
.popTaskTypeManage ul li {
    padding: 5px 15px;
    margin-bottom: 15px;
    overflow: hidden;
    transition: all 0.5s;
}
.popTaskTypeManage ul li:hover {
    background: rgba(100, 100, 100, 0.2);
    transition: all 0.5s;
}
.popTaskTypeManage li .name {
    float: left;
}
.popTaskTypeManage li .controlBox {
    float: right;
}
.popTaskTypeManage li .controlBox i {
    margin: 0 5px;
    transition: all 0.5s;
    cursor: pointer;
}
.popTaskTypeManage li .controlBox i:hover {
    color: #409eff;
    transition: all 0.5s;
}
.popTaskTypeManage .addBtn {
    cursor: pointer;
    background: rgba(100, 100, 100, 0.1);
    padding: 15px;
    text-align: center;
}
.kindofTaskList li {
    cursor: pointer;
}
.kindofTaskList .current {
    background: #409eff !important;
    color: #ffffff;
}
</style>