// 我的任务
<template>

    <div class="myTask baseBg">
        <div class="tabBox">
            <div class="header">
                <el-tabs v-model="activeName"
                         type="border-card"
                         @tab-click="handleClick">
                    <el-tab-pane label="我执行的任务"
                                 name="我执行的任务"></el-tab-pane>
                    <el-tab-pane label="我分配的任务"
                                 name="我分配的任务"></el-tab-pane>
                    <el-tab-pane label="统计信息"
                                 name="统计信息"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="tabContentBox">
                <!-- 我执行的任务 -->
                <div class="myDidTask"
                     v-if="activeName === '我执行的任务'">
                    <com-my-did-task></com-my-did-task>
                </div>
                <!-- 我分配的任务 -->
                <div class="myHandOutTask"
                     v-if="activeName === '我分配的任务'">
                    <com-my-hand-out-task></com-my-hand-out-task>
                </div>
                <!-- 统计信息 -->
                <div class="statisticalInfo"
                     v-if="activeName === '统计信息'">
                    <com-statistical-info></com-statistical-info>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import ComStatisticalInfo from "./components/myTask/comStatisticalInfo/index";
import ComMyHandOutTask from "./components/myTask/comMyHandOutTask/index";
import comMyDidTask from "./components/myTask/comMyDidTask/index";
export default {
    name: "myTask",

    props: [],

    components: {
        ComStatisticalInfo,
        ComMyHandOutTask,
        comMyDidTask,
    },

    data() {
        return {
            activeName: this.$route.query.toStatistical
                ? "统计信息"
                : "我执行的任务",
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 切换Tab
        handleClick(tab, event) {
            console.log(tab, event);
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>
