// 列表操作
<template>

    <div class="comTableCaozuo">
        <template v-if="commonJs.isShowBtn(detailRole, 'Btn_查看')">
            <el-link type="primary"
                     v-if="showBtn.indexOf('TaskDetails') !== -1"
                     @click="openpopTaskDetails(item)">查看</el-link>
        </template>

        <template v-if="commonJs.isShowBtn(detailRole, 'Btn_登记工时')">
            <el-link type="warning"
                     v-if="showBtn.indexOf('CheckWorkHours') !== -1"
                     @click="openpopCheckWorkHours(item)">登记工时</el-link>
        </template>

        <template v-if="commonJs.isShowBtn(detailRole, 'Btn_删除任务')">
            <el-popconfirm confirm-button-text="删除"
                           cancel-button-text="取消"
                           icon="el-icon-info"
                           icon-color="red"
                           @confirm="del(item)"
                           v-if="showBtn.indexOf('Del') !== -1"
                           title="真的要删除吗？">
                <el-link type="danger"
                         slot="reference">删除</el-link>
            </el-popconfirm>
        </template>

        <!-- 登记工时 -->
        <pop-check-work-hours v-if="popCheckWorkHoursVisible"
                              :item="item"
                              :id="item.id"
                              @close="closepopCheckWorkHours"></pop-check-work-hours>
        <!-- 任务详情 -->
        <pop-task-details v-if="popTaskDetailsVisible"
                          :id="item.id"
                          :item="item"
                          :taskStatus="status"
                          @close="closepopTaskDetails"></pop-task-details>

    </div>

</template>

<script>
import PopTaskDetails from "@/modleComponents/Task/popTaskDetails";
import PopCheckWorkHours from "./popCheckWorkHours";
import { Loading } from "element-ui";
import API from "@/api/taskManage.js";
export default {
    name: "comTableCaozuo",

    props: {
        /**
         * 行数据
         */
        item: {
            type: Object,
            default: null,
        },
        /**
         * 显示哪些按钮，可选项如下
         * TaskDetails 查看详情
         * 登记工时
         * Del 删除
         */
        showBtn: {
            type: Array,
            default() {
                return ["TaskDetails"];
            },
        },

        /**
         * 当前任务状态  已完成/未完成  已完成不能新建子任务
         */
        status: {
            default: 0,
        },
        /* 数据权限 */
        detailRole: {
            type: Object,
            default: null,
        },
    },

    components: {
        PopTaskDetails,
        PopCheckWorkHours,
    },

    data() {
        return {
            popCheckWorkHoursVisible: false, // 登记工时弹窗显示
            popTaskDetailsVisible: false, // 任务详情弹窗显示
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 登记工时 打开弹框
        openpopCheckWorkHours() {
            this.popCheckWorkHoursVisible = true;
        },
        // 登记工时 关闭弹框
        closepopCheckWorkHours() {
            this.popCheckWorkHoursVisible = false;
            this.$emit("update");
        },
        // 任务详情 打开弹框
        openpopTaskDetails() {
            this.popTaskDetailsVisible = true;
        },
        // 任务详情 关闭弹框
        closepopTaskDetails() {
            this.popTaskDetailsVisible = false;
            this.$emit("update");
        },
        // 删除任务
        del() {
            let loading = Loading.service({
                target: document.querySelector(".comTableCaozuo"),
            });
            API.delTask(this.item.id)
                .then(() => {
                    loading.close();
                    this.$emit("update");
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
// .comTableCaozuo{

// }
</style>
