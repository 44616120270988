// 任务模块
<template>

    <div class="tableControlBar">
        <div class="controlBar">
            <template v-if="commonJs.isShowBtn(detailRole, 'Btn_任务类型管理')">
                <el-button type="success"
                           v-if="showBtn.indexOf('TaskTypeManage') !== -1"
                           @click="openpopTaskTypeManage">任务类型管理</el-button>
            </template>
            <template v-if="commonJs.isShowBtn(detailRole, 'Btn_批量导入任务')">
                <el-button type="success"
                           v-if="showBtn.indexOf('ImportTask') !== -1"
                           @click="openpopImportTask">批量导入任务</el-button>
            </template>
            <template v-if="commonJs.isShowBtn(detailRole, 'Btn_新建任务')">
                <el-button type="success"
                           v-if="showBtn.indexOf('NewTask') !== -1"
                           @click="openpopNewTask">新建任务</el-button>
            </template>
            <template v-if="commonJs.isShowBtn(detailRole, 'Btn_休假信息同步')">
                <el-button type="warning"
                           class="synchroVacationBtn"
                           v-if="showBtn.indexOf('SynchroVacation') !== -1"
                           @click="handleSynchroVacation">休假信息同步</el-button>
            </template>
        </div>

        <!-- 新建任务 -->
        <popNewTask v-if="popNewTaskVisible"
                    @update="update"
                    @close="closepopNewTask"></popNewTask>
        <!-- 任务类型管理 -->
        <pop-task-type-manage v-if="popTaskTypeManageVisible"
                              @close="closepopTaskTypeManage"></pop-task-type-manage>
        <!-- 批量导入任务 -->
        <pop-import-task v-if="popImportTaskVisible"
                         @close="closepopImportTask"></pop-import-task>

    </div>

</template>

<script>
import PopImportTask from "./popImportTask";
import PopTaskTypeManage from "./popTaskTypeManage";
import PopNewTask from "./popNewTask";
import { Loading } from "element-ui";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "tableControlBar",

    props: {
        /**
         * 显示那些功能按钮
         * 可选值如下:
         * TaskTypeManage 任务类型管理
         * ImportTask 批量导入任务
         * NewTask 新建任务
         * SynchroVacation 休假信息同步
         */
        showBtn: {
            type: Array,
            default() {
                return ["TaskTypeManage", "ImportTask", "NewTask"];
            },
        },
        /* 数据权限 */
        detailRole: {
            type: Object,
            default: null,
        },
    },

    components: {
        PopImportTask,
        PopTaskTypeManage,
        PopNewTask,
    },

    data() {
        return {
            popTaskTypeManageVisible: false, // 任务类型管理弹窗显示
            popNewTaskVisible: false, // 新建任务弹窗显示
            popImportTaskVisible: false, // 导入任务弹窗显示
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 任务类型管理 打开弹框
        openpopTaskTypeManage() {
            this.popTaskTypeManageVisible = true;
        },
        // 任务类型管理 关闭弹框
        closepopTaskTypeManage() {
            this.popTaskTypeManageVisible = false;
        },
        // 导入任务 打开弹框
        openpopImportTask() {
            this.popImportTaskVisible = true;
        },
        // 导入任务 关闭弹框
        closepopImportTask() {
            this.popImportTaskVisible = false;
        },
        // 新建任务 打开弹框
        openpopNewTask() {
            this.popNewTaskVisible = true;
        },
        // 新建任务 关闭弹框
        closepopNewTask() {
            this.popNewTaskVisible = false;
        },
        // 休假信息同步
        handleSynchroVacation() {
            let loading = Loading.service({
                target: document.querySelector(".synchroVacationBtn"),
            });
            commonAPI
                .synchroVacation()
                .then(() => {
                    // 触发更新
                    this.update();
                    loading.close();
                    this.$message({ message: "操作成功！", type: "success" });
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 触发更新
        update() {
            this.$emit("update");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
// .tableControlBar{

// }
</style>
