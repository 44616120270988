// 通过关键词搜索项目
// TODO 由于改成了v-model形式，需要检查之前使用该组件的地方
<template>

    <div class="comSelectItemForKeyword">
        <el-select v-model="value"
                   filterable
                   remote
                   reserve-keyword
                   placeholder="请输入关键词搜索项目"
                   @change="changeItem"
                   :remote-method="remoteMethod"
                   :loading="loading"
                   :multiple="multiple"
                   clearable>
            <el-option v-for="item in options"
                       :key="item.code"
                       :label="item.label"
                       :value="item.code">
            </el-option>
        </el-select>
    </div>

</template>

<script>
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "comSelectItemForKeyword",
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        // v-model 双向绑定：接受父组件的值
        value: {
            type: String,
            default: "",
        },
        // 是否多选
        multiple: {
            type: Boolean,
            default: false,
        }
    },

    components: {},

    data() {
        return {
            loading: false,
            options: [],
            itemId: "", // 选中的项目id
            list: [],
        };
    },

    created() {},

    mounted() {
        if (this.value) {
            this.remoteMethod(this.value)
        }
    },

    methods: {
        remoteMethod(query) {
            if (query !== "") {
                this.loading = true;
                commonAPI
                    .linkItemSearch(query)
                    .then((res) => {
                        this.loading = false;
                        let data = res.content;
                        this.options = data;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            } else {
                this.options = [];
            }
        },
        // 选择项目
        changeItem() {
            this.$emit("selectItem", this.itemId);
        },

        // 清空项目
        cleanSelect() {
            this.itemId = "";
        },
    },

    computed: {},

    watch: {
        // 接收到父组件的value
        value: {
            deep: true,
            immediate: true,
            handler: function () {
                // 回显数据
                // this.showData();
                this.$emit("change", this.value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
// .comSelectItemForKeyword{

// }
</style>
