// 编辑分类
// popEditTaskKind
<template>

    <div class="popEditTaskKind">
        <el-dialog :title="editTaskKindPopTitle"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   width="30%"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     label-width="80px"
                     :inline="false">
                <el-form-item label="分类名称"
                              prop="name">
                    <el-input v-model="form.name"
                              placeholder="请输入分类名称"
                              clearable></el-input>
                </el-form-item>
                <div class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="save">保存</el-button>
                </div>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import { Loading } from "element-ui";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "popEditTaskKind",

    props: {
        // 弹窗显示的标题
        editTaskKindPopTitle: {
            type: String,
            default: "新增任务分类",
        },
        // 编辑的数据
        editTaskKindItem: {
            type: Object,
            default: null,
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,

            form: {
                module: "WORK_TASK_TYPE",
                name: "", // 分类名称
                status: true,
            },
            formRules: {
                // 分类名称
                name: [
                    {
                        required: true,
                        message: "请输入分类名称",
                    },
                ],
            },
        };
    },

    created() {},

    mounted() {
        if (this.editTaskKindItem) {
            this.form = Object.assign({}, this.editTaskKindItem);
        }
    },

    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 保存
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = Loading.service({
                        target: document.querySelector(".popEditTaskKind"),
                    });
                    let data = {
                        code: this.form.code || this.form.name,
                        name: this.form.name,
                        id: this.form.id || "",
                        module: this.form.module,
                        status: true,
                        version: this.form.version,
                    };
                    let apiName = this.form.id ? "editDict" : "saveDict";
                    commonAPI[apiName](data)
                        .then(() => {
                            loading.close();
                            this.$message({message: "操作成功！",type: "success"});
                            this.$emit("update");
                            this.close();
                        })
                        .catch(() => {
                            loading.close();
                        });
                } else {
                    this.$message({
                        message: "请检查必填信息是否填写完整！",
                        type: "error",
                    });
                    return false;
                }
            });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>