// 工时统计视图
<template>

    <div class="comHoursView">
        <div class="warp">
            <div class="timeBox">
                <el-date-picker v-model="time"
                                size="large"
                                type="month"
                                value-format="yyyy-MM"
                                format="yyyy年MM月"
                                :clearable="false"
                                @change="update()"
                                placeholder="请选择日期"></el-date-picker>
            </div>
            <div class="statistics">
                <p>完成任务数量：{{finishTask}}</p>
                <p>总工时：{{workHours}}（h）</p>
                <p>补填工时：{{wadTotalHours}}（h）</p>
                <p>提前率：{{advanceRate}}</p>
                <p>涉及项目：{{projectNum}}</p>
            </div>
            <div class="calendar"
                 v-if="eachDayData.length > 0">
                <el-calendar v-model="time">
                    <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
                    <template slot="dateCell"
                              slot-scope="{date, data}">
                        <div class="warp"
                             @click="details(data.day)">
                            <p class="day">
                                {{data.day.split('-')[2]}}
                                <span class="fillTip"
                                      v-if="isFill(data.day)">补</span>
                            </p>
                            <p class="info"
                               v-if="data.type === 'current-month'">
                                {{data.day | todayInfo}}
                            </p>
                            <template v-if="data.type === 'current-month'">
                                <div class="isGreaterThan"
                                     v-if="isGreaterThan(data.day)"></div>
                            </template>
                        </div>
                    </template>
                </el-calendar>
            </div>
            <div class="emptyBg"
                 v-else></div>
        </div>
    </div>

</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/taskManage.js";
let that = this;
export default {
    name: "comHoursView",

    props: [],

    components: {},

    data() {
        return {
            time: "", // 日期
            eachDayData: [], // 数据
            finishTask: "", // 完成任务数
            workHours: "", // 总工时
            wadTotalHours: "", // 补填工时
            advanceRate: "", // 提前率
            projectNum: "", // 涉及项目数
        };
    },

    created() {},

    mounted() {
        // 更新数据
        this.update();
    },

    methods: {
        // 更新数据
        update() {
            this.getList();
        },
        // 获取列表
        getList() {
            if (!this.time) {
                this.time = this.commonJs.nowTime().slice(0, 7);
            }
            let loading = Loading.service({
                target: document.querySelector(".comHoursView"),
            });
            API.statisticalHourView(this.time)
                .then((res) => {
                    loading.close();
                    this.advanceRate = res.content.advanceRate || "0%";
                    this.finishTask = res.content.finishTask || 0;
                    this.projectNum = res.content.projectNum || 0;
                    this.workHours = res.content.workHours || 0;
                    this.wadTotalHours = res.content.wadTotalHours || 0;
                    this.eachDayData = res.content.eachDayData || [];
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 查看详情
        details(day) {
            if (this.$moment(day) > this.$moment()) {
                this.$message({
                    message: "只可以查看今天之前的信息！",
                    type: "error",
                });
            } else {
                this.$router.push({
                    path: "/taskManage/hoursViewDetails",
                    query: {
                        day: day,
                    },
                });
            }
        },
        // 状态数据转换
        isGreaterThan: function (value) {
            if (!value) return "";
            let filterArr = that.eachDayData.filter((item) => {
                return value === item.eachDayTime;
            });
            if (filterArr[0]) {
                if (filterArr[0].isGreaterThan) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        // 是否是补填
        isFill(value) {
            if (!value) return "";
            let filterArr = that.eachDayData.filter((item) => {
                return value === item.eachDayTime;
            });
            let flag = false;
            if (filterArr[0] && filterArr[0].wad) {
                flag = true;
            }
            return flag;
        },
    },

    beforeCreate() {
        that = this;
    },

    computed: {},

    watch: {},

    filters: {
        // 状态数据转换
        todayInfo: function (value) {
            if (!value) return "";
            let filterArr = that.eachDayData.filter((item) => {
                return value === item.eachDayTime;
            });
            let data = [];
            if (filterArr[0]) {
                if (filterArr[0].workHours) {
                    data.push(`工时：${filterArr[0].workHours}（h）`);
                }
                if (filterArr[0].leaveHours) {
                    data.push(`调休：${filterArr[0].leaveHours}（h）`);
                }
            }
            return data.join(" ");
        },
    },
};
</script>

<style lang="scss" scoped>
// 日期
.timeBox {
    background: #ffffff;
    margin-bottom: 15px;
    border-radius: 8px;
}
.timeBox ::v-deep .el-input__inner {
    padding-left: 35px;
    font-size: 14px;
    font-weight: 400;
    color: #595959;
}
// 统计信息
.statistics {
    overflow: hidden;
    background: #fff;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 8px;
}
.statistics p {
    float: left;
    margin-right: 45px;
}
// 日历模块
.calendar {
    height: calc(100vh - 415px);
    overflow-y: auto;
}
.calendar .day {
    text-align: center;
}
.calendar .info {
    text-align: center;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
}
.calendar ::v-deep .el-calendar__header {
    display: none;
}
// 不足8小时
.calendar ::v-deep .el-calendar-day {
    position: relative;
}
.calendar ::v-deep .el-calendar-day .isGreaterThan {
    width: 100%;
    height: 100%;
    background: rgba(255, 0, 0, 0.05);
    position: absolute;
    top: 0;
    left: 0;
}
</style>
