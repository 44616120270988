// 编辑任务类型
// popEditTaskType
<template>

    <div class="popEditTaskType">
        <el-dialog :title="editTaskTypePopTitle"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   width="30%"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     label-width="80px"
                     :inline="false">
                <el-form-item label="分类名称"
                              prop="dictName">
                    <el-input v-model="dictName"
                              disabled
                              placeholder="请输入分类名称"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="分类名称"
                              prop="name">
                    <el-input v-model="form.name"
                              placeholder="请输入分类名称"
                              clearable></el-input>
                </el-form-item>
                <div class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="save">保存</el-button>
                </div>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import { Loading } from "element-ui";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "popEditTaskType",

    props: {
        // 弹窗显示的标题
        editTaskTypePopTitle: {
            type: String,
            default: "新增任务类型",
        },
        // 编辑的数据
        editTaskTypeItem: {
            type: Object,
            default: null,
        },
        // 字典Id
        dictId: {
            type: String,
            default: "",
        },
        // 字典名
        dictName: {
            type: String,
            default: "",
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,

            form: {
                name: "", // 分类名称
            },
            formRules: {
                // 分类名称
                name: [
                    {
                        required: true,
                        message: "请输入分类名称",
                    },
                ],
            },
        };
    },

    created() {},

    mounted() {
        if (this.editTaskTypeItem) {
            this.form = this.editTaskTypeItem;
        }
    },

    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 保存
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = Loading.service({
                        target: document.querySelector(".popEditTaskType"),
                    });
                    commonAPI
                        .saveDictItem({
                            name: this.form.name,
                            dictId: this.dictId,
                            id: this.form.id || "",
                            value: this.form.name,
                            version: this.form.version,
                            forbidFlag: false,
                        })
                        .then(() => {
                            this.close();
                            this.$emit("update");
                            loading.close();
                            this.$message({
                                message: "操作成功！",
                                type: "success",
                            });
                        })
                        .catch(() => {
                            loading.close();
                        });
                } else {
                    this.$message({
                        message: "请检查必填信息是否填写完整！",
                        type: "error",
                    });
                    return false;
                }
            });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>